<template>
  <div class="p-3 sku-exceptions-holder">

    <header class="mb-4">
      <BackButton class="exception-BackButton"></BackButton>
      <h3>Edit ship via exception {{ exceptionID }}</h3>
    </header>
    <client-exception-config
    :exceptionID="exceptionID">
    </client-exception-config>
  </div>
</template>

<script>

import ClientExceptionConfig from '../../../../components/OrderManagement/ShipVia/ClientShipViaExceptionConfig.vue';
import BackButton from '../../../../components/BackButton.vue';

export default {
  name: 'EditClientExceptions.vue',
  components: {
    'client-exception-config': ClientExceptionConfig,
    BackButton,
  },
  data() {
    return {
      exception: null,
    };
  },
  computed: {
    exceptionID() {
      return parseInt(this.$route.params.id, 10);
    },
  },
};
</script>

<style lang="scss">
  @import '../../../../styles/OrderManagement.scss';
</style>
